import React, { useState } from 'react';
import "./LoginScreen.css";
import SignUpScreen from './SignUpScreen';

function LoginScreen() {
  const [signIn, setSignIn] = useState(false);

  return (
    <div className="LoginScreen">
      <div className="navbar">
        <div className="nav_contents">
          <p>Ringles®</p>
          <button onClick={() => setSignIn(true)} className="LoginScreen_Button">Entrar</button>
        </div>
      </div>
      <div className="LoginScreen_Body">
        {signIn ? (
          <SignUpScreen />
        ) : (
          <div className="LoginScreen_Body">
            <h1>Aprenda com os melhores.</h1>
            <h2>Assista onde quiser, cancele a qualquer momento.</h2>
            <h3>Está pronto para começar, coloque seu e-mail e crie já sua conta!</h3>
            <div className="LoginScreen_Input">
              <form>
                <input type="email" placeholder='Endereço de e-mail'/>
                <button onClick={() => setSignIn(true)} className='LoginScreen_Começar'>
                  COMEÇAR
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginScreen;

