import React, { useEffect, useState } from 'react';
import './Nav.css';
import { useNavigate } from 'react-router-dom';

function Nav() {
  const [show, handleShow] = useState(false);

  const navigate = useNavigate();

  const transitionNavBar = () => {
    if (window.scrollY > 100) {
      handleShow(true);
    } else {
      handleShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionNavBar);
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, []);

  return (
    <div className={`nav ${show && 'nav_white'}`}>
      <div className="navbar_contents">
        <div onClick={() => navigate('/')} 
        className='Logo'>
        <p> Ringles®</p>
        </div>
        <img onClick={() => navigate('/profile')} 
          className="nav_avatar"
          src={process.env.PUBLIC_URL + '/avatar.png'}
          alt="Avatar"
        />
      </div>
    </div>
  );
}

export default Nav;
