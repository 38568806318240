import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import Nav from '../Nav';
import PlansScreen from './PlansScreen';
import db, { auth } from '../firebase';
import './ProfileScreen.css';

function ProfileScreen() {
  const user = useSelector(selectUser);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [username, setUsername] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;

        if (currentUser) {
          const userId = currentUser.uid;
          const userRef = db.collection('customers').doc(userId);

          const userData = (await userRef.get()).data();

          // If 'username' field doesn't exist, create it with an empty string
          if (!userData.hasOwnProperty('username')) {
            await userRef.update({
              username: '',
            });
          }

          setProfilePicUrl(userData.profilePic || process.env.PUBLIC_URL + '/avatar.png');
          setUsername(userData.username || '');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleUsernameClick = () => {
    setIsEditing(true);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSaveUsername = async () => {
    try {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userId = currentUser.uid;
        const userRef = db.collection('customers').doc(userId);

        // Update the 'username' field in the user's document
        await userRef.update({
          username: username,
        });

        console.log('Username updated successfully!');
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating username:', error);
    }
  };

  const handleSignOut = () => {
    auth.signOut();
    navigate('/');
  };

  return (
    <div className="profileScreen">
      <Nav />
      <div className="profileScreen_body">
        <div className="profileScreen_info">
        <img src={profilePicUrl || process.env.PUBLIC_URL + '/avatar.png'} alt="Avatar" />
          <div className="profileScreen_details">
            <div className="profileScreen_username">
              {isEditing ? (
                <div className="profileScreen_editing">
                  <input type="text" value={username} onChange={handleUsernameChange} />
                  <button onClick={handleSaveUsername}>Salvar</button>
                </div>
              ) : (
                <span onClick={handleUsernameClick} className="profileScreen_editable">
                  {username || 'Adicionar nome de usuário'}
                </span>
              )}
            </div>
            <h2>{user.email}</h2>
            </div>
            </div>
            <div className="profileScreen_plans">
              <h3>Planos</h3>
              <PlansScreen />
              <button onClick={handleSignOut} className="profileScreen_signOut">
                Sair da minha conta
              </button>
            </div>
      </div>
    </div>
  );
}

export default ProfileScreen;
