import React, { useEffect, useState } from 'react';
import './PlansScreen.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import db from '../firebase';

const stripePromise = loadStripe('pk_test_51OC8FrDrL5CYjwZDfukzlp455szEtjCXU1jwW44j8FOJVAtnqyzHMXEotFkjp5NB0o1s6xf6Tr11mE8y3l8DStNy002z1VdlgR');

function PlansScreen() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await db.collection('products').where('active', '==', true).get();

        const updatedProducts = await Promise.all(querySnapshot.docs.map(async (productDoc) => {
          const productData = productDoc.data();

          const priceSnap = await db.collection('products').doc(productDoc.id).collection('prices').get();

          const prices = priceSnap.docs.map((price) => ({
            priceId: price.id,
            priceData: price.data(),
          }));

          return {
            productId: productDoc.id,
            productData,
            prices,
          };
        }));

        setProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, []);

  const handleSubscribe = async (priceId) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: 'subscription',
      successUrl: `${window.location.origin}/profile`,
      cancelUrl: `${window.location.origin}/profile`,
    });

    if (error) {
      console.error('Error redirecting to Checkout:', error);
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="plansScreen">
        {products.map((product) => (
          <div className='planScreen_plan' key={product.productId}>
            <div className='planScreen_info'>
              <h5>{product.productData.name}</h5>
              <h6>{product.productData.description}</h6>
            </div>
            <button onClick={() => handleSubscribe(product.prices[0].priceId)}>Assinar</button>
          </div>
        ))}
      </div>
    </Elements>
  );
}

export default PlansScreen;
