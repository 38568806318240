import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import db, { auth } from '../firebase';
import './CourseDashboard.css';
import Nav from '../Nav';

const CourseDashboard = () => {
  const { courseId, chapterId } = useParams();
  const videoRef = useRef(null);
  const [chapter, setChapter] = useState({});
  const [isWatched, setIsWatched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChapter = async () => {
      try {
        const chapterDoc = await db
          .collection('courses')
          .doc(courseId)
          .collection('chapters')
          .doc(chapterId)
          .get();

        if (chapterDoc.exists) {
          const chapterData = chapterDoc.data();
          setChapter(chapterData);
          setIsWatched(chapterData?.watched || false);
        } else {
          console.error('Chapter not found');
        }
      } catch (error) {
        console.error('Error fetching chapter:', error);
      }
    };

    fetchChapter();
  }, [courseId, chapterId]);

  useEffect(() => {
    if (chapter.videoUrl) {
      const player = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        fluid: true,
        sources: [
          {
            src: chapter.videoUrl,
            type: 'video/mp4',
          },
        ],
      });

      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
  }, [chapter]);

  const markAsDone = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.warn('User not signed in');
        return;
      }

      const userId = currentUser.uid;
      const userProgressRef = db.collection('customers').doc(userId).collection('progress').doc(courseId);
      
      // Check if the progress document exists for the course
      const progressDoc = await userProgressRef.get();

      if (!progressDoc.exists) {
        // If not, create the progress document with the current chapter marked as watched
        await userProgressRef.set({
          [chapterId]: { watched: true },
        });
      } else {
        // If it exists, update the current chapter as watched
        await userProgressRef.update({
          [chapterId]: { watched: true },
        });
      }

      setIsWatched(true);
      navigate(`/course/${courseId}`);
    } catch (error) {
      console.error('Error marking chapter as done:', error);
    }
  };

  return (
    <div className={`course_Dashboard course-${courseId}`}>
      <Nav />
      <div className="course_Content">
        <div className='Player'>
          <div className="custom-video-player">
            <video ref={videoRef} className="video-js vjs-big-play-centered" />
          </div>
          <h2>{chapter.title}</h2>
          <div className='description_Box'>
            <p>{chapter.description}</p>
          </div>
        </div>
        <div className='checkbox_Button'>
          <p> {isWatched ? '' : ''}</p>
          <button onClick={markAsDone}>Próximo</button>
        </div>
      </div>
    </div>
  );
};

export default CourseDashboard;
