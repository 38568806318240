import React, { useState, useEffect } from 'react';
import './Banner.css';
import db from './firebase';
import { Link } from 'react-router-dom';

function Banner() {
  const [courses, setCourses] = useState([]);
  const [currentCourseIndex, setCurrentCourseIndex] = useState(0);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesSnapshot = await db.collection('courses').get();
        const coursesData = coursesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCourses(coursesData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTransition(true);

      // Delay the state update for smooth transition
      setTimeout(() => {
        setCurrentCourseIndex(prevIndex => (prevIndex + 1) % courses.length);
      }, 500);
    }, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [courses, transition]);

  function truncate(string, n) {
    if (string && string.length > n) {
      return string.substr(0, n - 1) + '...';
    } else {
      return string;
    }
  }

  const currentCourse = courses[currentCourseIndex] || {};

  return (
    <header
      className={`banner ${transition ? 'transition' : ''}`}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${currentCourse.banner || ''})`,
        backgroundPosition: 'center center',
      }}
    >
      <div className="banner_contents">
        <h1 className="banner_title">{currentCourse.title}</h1>
        <div className="banner_description">{truncate(currentCourse.description, 200)}</div>
        <div className="banner_buttons">
          {/* Use Link to create a link to the course details */}
          <Link to={`/course/${currentCourse.id}`}>
            <button className="banner_button_1">Assistir agora &rarr;</button>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Banner;
