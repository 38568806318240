import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import db, { auth } from '../firebase'; // Make sure to import auth
import './CourseDetails.css';
import Nav from '../Nav';


function truncate(string, n) {
  if (string && string.length > n) {
    return string.substr(0, n - 1) + '...';
  } else {
    return string;
  }
}

function CourseDetails() {
  const { courseId } = useParams();
  const [course, setCourse] = useState({});
  const [chapters, setChapters] = useState([]);
  const [liveBanner, setLiveBanner] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [userProgress, setUserProgress] = useState({}); // Added state for user progress

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const courseDoc = await db.collection('courses').doc(courseId).get();

        if (courseDoc.exists) {
          setCourse(courseDoc.data());

          const chaptersCollection = await db.collection('courses').doc(courseId).collection('chapters').get();

          if (!chaptersCollection.empty) {
            const chaptersData = chaptersCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setChapters(chaptersData);
          } else {
            console.error('No chapters found');
          }

          // Fetch live banner and meeting link
          const liveBannerData = courseDoc.data().liveBanner;
          const meetingLinkData = courseDoc.data().meetingLink;

          setLiveBanner(liveBannerData);
          setMeetingLink(meetingLinkData);

          // Fetch user progress
          const currentUser = auth.currentUser;
          if (currentUser) {
            const userId = currentUser.uid;
            const progressDoc = await db.collection('customers').doc(userId).collection('progress').doc(courseId).get();
            setUserProgress(progressDoc.data() || {});
          }
        } else {
          console.error('Course not found');
        }
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };

    fetchCourseDetails();
  }, [courseId]);


  return (
    <div className="coursedetails">
      <Nav />
      <div className="banner" style={{ backgroundSize: 'cover', backgroundImage: `url(${course.banner || ''})`, backgroundPosition: 'center center' }}>
      <div className="banner_contents">
          <h1 className="banner_title">{truncate(course.title, 50)}</h1>
          <p className="banner_description">{truncate(course.description, 150)}</p>
          {course.professorName && (
            <div className="professor_info">
              <img className="professor_photo" src={course.professorPhoto} alt="Professor" />
              <p className="professor_name">{course.professorName}</p>
            </div>
          )}
        </div>
      </div>
      <h3>Capítulos</h3>
      <div className="course_content">
        <div className="chapters_row">
          {chapters.map((chapter) => (
            <div key={chapter.id} style={{ textDecoration: 'none' }}>
              <Link to={`/course/${courseId}/chapter/${chapter.id}`} style={{ textDecoration: 'none' }}>
                <div className={`chapter_video ${userProgress[chapter.id]?.watched ? 'watched' : ''}`} >
                  {userProgress[chapter.id]?.watched && <div className="watched_overlay">&#10003;</div>}
                  <img
                    src={chapter.thumbnailUrl}
                    alt={`Chapter ${chapter.id}`}
                    style={{ width: '400px', height: '250px', objectFit: 'cover', borderRadius: '15px' }}
                  />
                </div>
              </Link>
              <div className='video_description'>
                <p>Aula {chapter.id.replace(/\D/g, '')}</p>
                <h3>{truncate(chapter.title, 45)}</h3>
                <p>{truncate(chapter.description, 150)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="live_Banner">
      <h3>Aula ao vivo</h3>
  {liveBanner && (
    <div className='live_Banner_Content' style={{ position: 'relative' }}>
      <img src={liveBanner} alt="Live Banner" style={{ maxWidth: '100%', height: 'auto', WebkitBorderRadius: '20px' }} />

      <a href={meetingLink} target="_blank" rel="noopener noreferrer" style={{ position: 'absolute', bottom: '20px', left: '20px', textDecoration: 'none' }}>
        <button style={{ padding: '15px', fontSize: '16px', backgroundColor: '#FC5C00', color: '#FEFEFE', border: 'none', borderRadius: '10px', cursor: 'pointer' }}>Entrar na aula</button>
      </a>
    </div>
  )}
</div>

    </div>
  );
}

export default CourseDetails;
