import React, { useRef } from 'react';
import { auth } from '../firebase'; 
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import './SignUpScreen.css';

const SignUpScreen = () => {

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const register = async (e) => {
    e.preventDefault();

    try {
      const user = await createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value);
      console.log(user);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        alert('Este endereço de e-mail já está sendo usado por outra conta. Por favor, use um endereço de e-mail diferente.');
      } else {
        alert(error.message);
      }
    }
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      const user = await signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value);
      console.log(user);
    } catch (error) {
      if (error.code === 'auth/invalid-login-credentials') {
        alert('E-mail ou senha incorretos. Por favor, verifique os campos tente novamente.');
      } else {
        alert(error.message);
      }
    }
  };


  return (
    <div className='signupScreen'>
      <form>
        <h1>Entrar</h1>
        <input ref={emailRef} type="email" name="email" placeholder='Email'/>
        <input ref={passwordRef} type="password" name="password" placeholder="Password"/>
        <button type='submit' onClick={login}>Entrar</button>
        <h4>
          <span className='signupScreen_gray'>
            Novo por aqui? </span>
          <span className='signupScreen_link' onClick={register}>
            Criar conta agora
          </span>
        </h4>
      </form>
    </div>
  );
};

export default SignUpScreen;
