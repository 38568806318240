import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: 'AIzaSyCKHQGCmHICqNPqahARpvo5uh-a7HQcXvk',
  authDomain: 'ringles.firebaseapp.com',
  projectId: 'ringles',
  storageBucket: 'ringles.appspot.com',
  messagingSenderId: '675459706664',
  appId: '1:675459706664:web:5e8d6c4d032f8793be6a1d',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth };
export default db;